<template>
    <div>
        <component :is="componentMapping[country]" :brandConfig="brandConfig" :country="country" :storeId="storeId"
            :storeName="storeName" />
    </div>
</template>

<script>
import mixins from "./mixin/Registration.js";
export default {
    mixins: [mixins],
    components: {
        KR: () => import("./KR/EnterOpt.vue"),
        JPN: () => import("./JPN/EnterOpt.vue"),
    },
    data() {
        return {
            componentMapping: {
                KR: "KR",
                JPN: "JPN",
            },
        };
    },
}
</script>

<style scoped></style>
